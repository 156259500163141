export default {
  // chủ đề tin tức
  GROUP_NEW_GET_PAGING: 'GroupNew/get-paging',
  GROUP_NEW_CREATE: 'GroupNew/create',
  GROUP_NEW_EDIT: 'GroupNew/update',
  GROUP_NEW_DELETE: 'GroupNew/delete',
  // quản lý tin tức
  NEW_MANAGER_GET_PAGING: 'NewManager/get-paging',
  NEW_MANAGER_CREATE: 'NewManager/create',
  // Xóa bài viết - tin tức
  NEW_CONTENT_MANAGER_DELETE: 'NewContent/delete',
}
