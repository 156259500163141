<template>
  <div>
    <n-c-list
      :nclist-id="'nclist-tin-tuc'"
      :nclistbtn-add-title="'Thêm mới tin tức'"
      :nclist-type="2"
      :nc-group-id="2"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import NCList from '@/views/new-content-manager/pages/NCList.vue'

export default {
  components: {
    NCList,
    BButton,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
