<template>
  <div
    :id="nclistId"
    class="page-container"
  >
    <!--phần header-->
    <n-button-header
      :content-btn-add="nclistbtnAddTitle"
      :show-btn-multi-delete="false"
      @clickAdd="handler_open_btnFunc(1)"
    />
    <!--Phần bảng-->
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataRows || []"
      :sort-options="{
        enabled: false,
      }"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Tên-->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-media>
            <template #aside>
              <user-avatar :data="props.row" />
            </template>
            <span class="font-weight-bold text-name">{{ props.row.name }}</span>
          </b-media>
        </span>

        <!-- Column: Loại nội dung -->
        <span v-else-if="props.column.field === 'categoryName'">
          <span class="text-nowrap text-right">{{ props.row.categoryName }}</span>
        </span>

        <!-- Column:địa chỉ-->
        <span v-else-if="props.column.field === 'author'">
          <b-row>
            <b-col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center"
            >
              {{ props.row.author }}
            </b-col>
            <b-col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center nmt-5px"
            >
              {{ props.row.datetimeCreate | formatDateToDDMM }}
            </b-col>
          </b-row>
        </span>

        <!--   Column:Trạng thái -->
        <span v-else-if="props.column.field === 'statusName'">
          <n-form-status
            :status-id="props.row.statusId"
            :status-name="props.row.statusName"
          />
        </span>
        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'actionFunction'">
          <span
            @click="handler_open_btnFunc(2, props.row.id)"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            v-if="nclistType > 1"
            class="ml-2"
            @click="handler_open_btnFunc(3, props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :total-items="totalRecord"
      :current-page="urlQuery.currentPage"
      @pageClick="handlePageClick"
    />

    <!-- Modal xóa-->
    <modal-delete-no-param
      id-modal-delete-no-param="idModalQuestion"
      :type="modalType"
      :content-tile="modalContent"
      @accept="handle_run_question"
    />
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { axiosApiInstance } from '@core/auth/jwt/axiosApiInstance'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import NActionCarCatolog from '@/views/ndev-component/action/NActionCarCatolog.vue'
import NFormStatus from '@/views/ndev-component/NFormStatus.vue'
import NButtonHeader from '@/views/ndev-component/component/NButtonHeader.vue'
import NewContantConstantsApi from '@/views/new-content-manager/constants/NewContantConstantsApi'
import ConstantsApi from '@/views/news/constants/ConstantsApi'

export default {
  components: {
    NButtonHeader,
    NFormStatus,
    ModalDeleteNoParam,
    NActionCarCatolog,
    VueGoodTable,
    BButton,
    MyPagination,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BBadge,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    nclistId: {
      type: String,
      default: 'nclist_id',
    },
    nclistbtnAddTitle: {
      type: String,
      default: 'Thêm mới',
    },
    nclistType: {
      type: Number,
      default: 0,
    },
    ncGroupId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
      columns: [
        {
          label: 'Tiêu đề',
          field: 'name',
        },
        {
          label: 'Nhóm nội dung',
          field: 'categoryName',
        },
        {
          label: 'Tác giả',
          field: 'author',
        },
        {
          label: 'Trạng thái',
          field: 'statusName',
        },
        {
          label: 'Chức năng',
          field: 'actionFunction',
          thClass: 'custom-action-table-class',
          tdClass: 'text-center',
        },
      ],
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
        groupId: this.ncGroupId,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '', // thông báo xóa
      showBtnMultiDelete: false,
      deleteIds: [], // ID xóa
      isActive: false,
      dataRows: [], // dữ liệu bảng
      totalRecord: 10, // tổng số trang
      modalType: '',
    }
  },
  computed: {},
  mounted() {
    this.fetchDataList()
  },
  methods: {
    // =========================hàm mới tạo ====================================//
    async fetchDataList() {
      const iResult = await NCoreHelper.excuteGET(this, NewContantConstantsApi.NEWS_DATA_GET_PAGING, this.urlQuery)
      this.dataRows = iResult.pageLists
      this.totalRecord = iResult.totalRecord
    },
    // -----phần tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        await this.fetchDataList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        await this.fetchDataList()
      }
    },
    // -----phần phân trang
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchDataList()
    },
    selectRowTable(val) {
      this.deleteIds = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteIds.length > 0
    },
    // =========================================================================//
    getExecuteAction(type, data) {
      return NCoreHelper.GetExecuteAction(type, data)
    },
    handler_open_btnFunc(type, data) {
      switch (type) {
        case 1: {
          // Thêm mới
          switch (this.nclistType) {
            case 1: {
              // Giới thiệu
              this.$router.push({ name: 'nc-gioi-thieu-add' })
              break
            }
            case 2: {
              // Tin tức
              this.$router.push({ name: 'nc-tin-tuc-add' })
              break
            }
            default: {
              break
            }
          }
          break
        }
        case 2: {
          // Cập nhật
          switch (this.nclistType) {
            case 1: {
              // Giới thiệu
              this.$router.push({
                name: 'nc-gioi-thieu-update',
                params: { id: data },
              })
              break
            }
            case 2: {
              // Tin tức
              this.$router.push({
                name: 'nc-tin-tuc-update',
                params: { id: data },
              })
              break
            }
            default: {
              break
            }
          }
          break
        }
        case 3: {
          // Xóa
          this.deleteIds = []
          this.deleteIds.push(data)
          this.modalContent = this.$t('Bạn có muốn xóa không?')
          this.$bvModal.show('idModalQuestion')
          break
        }
        default: {
          // Thêm mới
          break
        }
      }
    },
    async handle_run_question() {
      const modal = { model: JSON.stringify(this.deleteIds) }
      await axiosApiInstance.delete(ConstantsApi.NEW_CONTENT_MANAGER_DELETE, { params: modal })
        .then(res => {
          if (res.status === 200) {
            this.$bvToast.toast('Xóa thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide('idModalQuestion')
            this.fetchDataList()
          } else {
            this.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">

</style>
