var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container",attrs:{"id":_vm.nclistId}},[_c('n-button-header',{attrs:{"content-btn-add":_vm.nclistbtnAddTitle,"show-btn-multi-delete":false},on:{"clickAdd":function($event){return _vm.handler_open_btnFunc(1)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataRows || [],"sort-options":{
      enabled: false,
    },"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('user-avatar',{attrs:{"data":props.row}})]},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold text-name"},[_vm._v(_vm._s(props.row.name))])])],1):(props.column.field === 'categoryName')?_c('span',[_c('span',{staticClass:"text-nowrap text-right"},[_vm._v(_vm._s(props.row.categoryName))])]):(props.column.field === 'author')?_c('span',[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_vm._v(" "+_vm._s(props.row.author)+" ")]),_c('b-col',{staticClass:"text-center nmt-5px",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.datetimeCreate))+" ")])],1)],1):(props.column.field === 'statusName')?_c('span',[_c('n-form-status',{attrs:{"status-id":props.row.statusId,"status-name":props.row.statusName}})],1):(props.column.field === 'actionFunction')?_c('span',[_c('span',{on:{"click":function($event){return _vm.handler_open_btnFunc(2, props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"}})],1),(_vm.nclistType > 1)?_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.handler_open_btnFunc(3, props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1):_vm._e()]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"total-items":_vm.totalRecord,"current-page":_vm.urlQuery.currentPage},on:{"pageClick":_vm.handlePageClick}}),_c('modal-delete-no-param',{attrs:{"id-modal-delete-no-param":"idModalQuestion","type":_vm.modalType,"content-tile":_vm.modalContent},on:{"accept":_vm.handle_run_question}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }